/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('../fonts/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../fonts/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}


.er-color-yellow {
    color: #f5fe69;
}

.er-darkest-green {
    color: #063e19;
}

.er-color-dark-green {
    color: #669915;
}

.er-bg-dark-green {
    background: #669915;
}

.er-color-orange {
    color: #fd9206;
}

.button-green {
    background: #399946;
}

.er-bg-green {
    background: #a3d55f;
}

.er-bg-gray {
    background: #f4f4f4;
}

.br-5 {
    border-radius: 5% !important;
}

@media (max-width: 991px) {
    .mobile-flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

* {
    font-family: 'Open Sans', sans-serif;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.font-size-3 {
    font-size: 3rem;
}

.font-size-5 {
    font-size: 5rem;
}

@media (max-width: 540px) {
    .font-size-3 {
        font-size: 2rem;
    }

    .font-size-5 {
        font-size: 4rem;
    }
}

.full-width {
    width: 100% !important;
}

.full-height {
    height: 100% !important;
}

@media (max-width: 991px) {
    .is-hidden-touch {
        display: none;
    }
}






/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 240px !important;
}

/* General sidebar styles */
.bm-menu {
    background: #ffc;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #ffc;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    display: flex;
    flex-direction: column;
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: black !important;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.color-white {
    color: white !important;
}

.slider-pic {
    height: auto;
    max-height: calc(100vh - 160px);
    width: 100% !important;
    object-fit: cover;
}

.color-black {
    color: black !important;
}